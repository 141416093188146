<template>
  <div class="coins_bg flex-grow-1 d-flex align-items-center justify-content-center">
    <div class="text-center">
      <img class="mb-3" :src="require('@/assets/images/emoji_2_clean.png')" alt="">

      <p class="h3 mb-3 text-center">
        Your account's been topped up! <br> Time to roll with it and enjoy the game!
      </p>

      <b-btn to="/" class="w-100 rounded-pill button-gradient-primary border-0" >
        Play
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "successful_payment",
}
</script>

<style scoped>

</style>